<template>
  <div>
    <div class="hand">
      <div class="hand-left">
        <div class="tip1">融资管理系统</div>
        <div class="tip2">融资需求计划到债务偿还全生命周期管理数字化</div>
        <div class="tip3">
          <div class="btn1" @click="pageHref">联系我们</div>
          <!-- <div class="btn2" @click="viewMore">查看更多</div> -->
        </div>
      </div>
    </div>
    <!-- 4大难题，阻滞融资机构业务发展 -->
    <div class="problem-box">
      <div class="title">4大难题，阻滞融资机构业务发展</div>
      <div class="content">
        <div class="box" v-for="(item, i) in problemList" :key="i">
          <div class="tip1" v-html="item.name"></div>
          <div class="tip2">{{ item.tips }}</div>
          <img class="img" :src="item.img" />
        </div>
      </div>
    </div>
    <!-- 击破难题，推出融资管理系统 -->
    <div class="manage-box">
      <div class="title">击破难题，推出融资管理系统</div>
      <div class="content">
        <div class="box" v-for="(item, i) in manageList" :key="i">
          <div class="flex">
            <div class="tips1">
              <div>{{ item.name }}</div>
              <div class="line">
                <div class="line1"></div>
                <div class="line2"></div>
              </div>
            </div>
            <img class="img" :src="item.img" />
          </div>
          <div class="tips2">{{ item.tips }}</div>
        </div>
      </div>
    </div>
    <!-- 4大优势，助力融资机构提效降本合规 -->
    <div class="advantage-box">
      <div class="title">4大优势，助力融资机构提效降本合规</div>
      <div class="content">
        <div class="box" v-for="(item, i) in advantageList" :key="i">
          <div class="box-flex">
            <img class="img" :src="item.img" />
            <div class="text-left">
              <div class="zsImg"></div>
              <div>
                <div class="tips">{{ item.name }}</div>
                <div class="tips2 ml24">{{ item.hoverTips }}</div>
              </div>

            </div>
   
          </div>
          <div class="hoverTips">
            <img class="hoverImg" :src="item.hoverImg" />
            <div class="center-box">
              <div class="wh100 flex-direction">
                <div class="tips">{{ item.name }}</div>
                <div class="tips2">{{ item.hoverTips }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 融资管理系统功能 -->
    <div class="manage-box2">
      <div class="title">融资管理系统功能</div>
      <div class="content mb20">
        <div class="box" v-for="(item, i) in manageList2" :key="i" :style="{backgroundImage:'url('+item.img+')',backgroundSize:'100% 100%'}">
          <div class="flex">
            <div class="tips1">
              <div>{{ item.name }}</div>
              <div class="line">
                <div class="line2"></div>
              </div>
            </div>
          </div>
          <div class="point-div">
            <div v-for="(items,index) in item.point" :key="index" class="point-item">
              <span class="o-dot"></span>
              <div>{{ items.tag }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="box" v-for="(item, i) in manageList3" :key="i" :style="{backgroundImage:'url('+item.img+')',backgroundSize:'100% 100%'}">
          <div class="flex">
            <div class="tips1">
              <div>{{ item.name }}</div>
              <div class="line">
                <div class="line2"></div>
              </div>
            </div>
          </div>
          <div class="point-div">
            <div v-for="(items,index) in item.point" :key="index" class="point-item">
              <span class="o-dot"></span>
              <div>{{ items.tag }}</div>
            </div>
          </div>
        </div>
        </div>
    </div>
     <!-- 融资债务系统 -->
    <div class="rzzwxt">
        <div class="title">融资债务系统</div>
        <div class="content">
            <img src="@/assets/project/rzzwxt_bg.png" />
        </div>
      </div>
    <!-- 标杆客户 -->
    <div class="client-box">
      <div class="title">标杆客户</div>
      <div class="content">
        <img src="@/assets/project/yid.png" alt="" class="img" />
        <img src="@/assets/project/liant.png" alt="" class="img" />
        <img src="@/assets/project/dianx.png" alt="" class="img" />
        <img src="@/assets/project/dat.png" alt="" class="img" />
        <img src="@/assets/project/xink.png" alt="" class="img" />
        <img src="@/assets/project/shuz.png" alt="" class="img" />
        <img src="@/assets/project/chengf.png" alt="" class="img" />
        <img src="@/assets/project/gangt.png" alt="" class="img" />
        <img src="@/assets/project/yanc.png" alt="" class="img" />
        <img src="@/assets/project/xiangt.png" alt="" class="img" />
        <img src="@/assets/project/yuns.png" alt="" class="img" />
        <img src="@/assets/project/shangh.png" alt="" class="img" />
        <img src="@/assets/project/zhongg.png" alt="" class="img" />
        <img src="@/assets/project/zhongk.png" alt="" class="img" />
        <img src="@/assets/project/gongz.png" alt="" class="img" />
        <img src="@/assets/project/zhongr.png" alt="" class="img" />
        <img src="@/assets/project/shizy.png" alt="" class="img" />
        <img src="@/assets/project/huan.png" alt="" class="img" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'project',
  components: {},
  props: {},
  data () {
    return {
      problemList: [
        {
          name: '存在重大财务<br>风险',
          tips: '融资、投资决策不规范，没有依据',
          img: require('../assets/project/1.png')
        },
        {
          name: '资金利用效率不高<br>资金闲散',
          tips: '资金紧张和冗余现象并存，财务费用较大，不能发挥整体效益。',
          img: require('../assets/project/2.png')
        },
        {
          name: '违规付款行为无法<br>及时监管',
          tips: '分散支付，集团公司不能有效监控子公司融资融信类支付资金的合理性。',
          img: require('../assets/project/3.png')
        },
        {
          name: '资金管理薄弱<br>家底不清',
          tips: '账户分散、票据分散，不能迅速掌握整体资金的存量、流量、流向。',
          img: require('../assets/project/4.png')
        }
      ],
      manageList: [
        {
          name: '融前管理',
          tips: '融前测算分析、授信额度管控、融前预算评估、融前业务管理',
          img: require('../assets/project/5.png')
        },
        {
          name: '融资过程管理',
          tips: '经营性债务日常管理、普通融资流程、专项债融资流程、利息精准测算、融中风险监控',
          img: require('../assets/project/6.png')
        },
        {
          name: '融后管理',
          tips: '债务功能、还款管理、融后预警、数据中心',
          img: require('../assets/project/7.png')
        }
      ],
      advantageList: [
        {
          name: '全数字化管理过程',
          img: require('../assets/project/01.png'),
          hoverTips:
            '实现融资项目融前、融中、融后全生命周期管理，切实做到融前分析、融中监控、融后预警，将业务全流程纳入数字化管理',
          hoverImg: require('../assets/project/zhouq.png')
        },
        {
          name: '加强精细化管控',
          img: require('../assets/project/02.png'),
          hoverTips:
            '对融资总量、担保额度、抵质押统一管理，规范融资行为，控制新增债务合理范围内，满足企业融资稳定可续发展。',
          hoverImg: require('../assets/project/guank.png')
        },
        {
          name: '建立风险监控体系',
          img: require('../assets/project/03.png'),
          hoverTips:
            '建立债务检测体系，实时监测信用、操作、资金等，通过设定预警指标，及时发出风险预警，帮助企业及时解决潜在风险。',
          hoverImg: require('../assets/project/fengx.png')
        },
        {
          name: '提供经营管理数据决策依据',
          img: require('../assets/project/04.png'),
          hoverTips:
            '建立数据中心解决领导融资债务报表需求，通过融资数据全过程分析控制融资体量，同时为管理者提供数据支撑与决策。',
          hoverImg: require('../assets/project/shuj.png')
        }
      ],
      manageList2: [
          {
            name: '融前管理',
            point: [
              {
                tag:'融前计划管理'
              },
              {
                tag:'融前测算分析'
              },
              {
                tag:'融前预算评估'
              },
              {
                tag:'融前业务管理'
              }, {
                tag:'授信额度管控'
              }
            ],
            img: require('../assets/project/rz_icon_1.png')
          },
          {
            name: '融中管理',
            point: [
              {
                tag:'客商管理'
              },
              {
                tag:'预算管理'
              },
              {
                tag:'立项管理'
              },
              {
                tag:'合同管理'
              },
               {
                tag:'提款管理'
              }
            ],
            img: require('../assets/project/rz_icon_2.png')
          },
          {
            name: '融后管理',
            point: [
              {
                tag:'还款管理'
              },
              {
                tag:'担保管理'
              },
              {
                tag:'档案管理'
              },
              {
                tag:'项目管理'
              },
               {
                tag:'偿债资金落实'
              }
            ],
            img: require('../assets/project/rz_icon_3.png')
          }
        ],
        manageList3:[
         {
            name: '债务管理',
            point: [
              {
                tag:'债券管理'
              },
              {
                tag:'融资债务监管'
              },
              {
                tag:'经营性债务日常管理'
              },
              {
                tag:'政府专项债融资流程'
              }
            ],
            img: require('../assets/project/rz_icon_4.png')
          },
          {
            name: '资金管理',
            point: [
              {
                tag:'数据中心'
              },
              {
                tag:'融资台账'
              },
              {
                tag:'资金监测'
              },
              {
                tag:'利息精准测算'
              }
            ],
            img: require('../assets/project/rz_icon_5.png')
          },
          {
            name: '风险预警管理',
            point: [
              {
                tag:'授信额度预警'
              },
              {
                tag:'资金余额预警'
              },
              {
                tag:'担保余额预警'
              },
              {
                tag:'债务到期预警'
              }
            ],
            img: require('../assets/project/rz_icon_6.png')
          }
        ],
    }
  },
  created () {},
  computed: {},
  methods: {
    pageHref () {
      this.$router.push({
        path: '/tech/about',
        query: {
          vs: 1
        }
      })
    },
    viewMore () {
      this.$router.push('/tech/about')
    }
  }
}
</script>
<style lang="scss" scoped>
.hand {
  position: relative;
  background-image: url("../assets/project/banner.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 575px;
  width: 100%;
overflow: hidden;
  .hand-left {
    position: relative;
    left: 13.8%;
    top: 160px;
    .tip1 {
      font-family: Alimama ShuHeiTi, Alimama ShuHeiTi;
      font-weight: bold;
      font-size: 71px;
      color: #ffffff;
      font-style: normal;
      text-transform: none;
    }

    .tip2 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 20px;
      color: #d0d4e1;
      margin-top: 28px;
      letter-spacing: 2px;
      font-style: normal;
      text-transform: none;
    }
    .tip3 {
      margin-top: 80px;
      display: flex;
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 20px;
      color: #ffffff;

      .btn1 {
        width: 180px;
        height: 50px;
        background: #ff892e;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #ff892e;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .btn2 {
        width: 110px;
        height: 50px;
        background: rgba(255, 137, 46, 0);
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 11px;
        cursor: pointer;
      }
    }
  }
}
.problem-box {
  position: relative;
  height: 678px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .title {
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: bold;
    font-size: 42px;
    color: #000000;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin: 64px auto 52px auto;
    height: fit-content;
  }
  .content {
    display: flex;
    margin: 0px auto;
    .box {
      transition: all 1.2s ease;
      width: 332px;
      height: 419px;
      padding: 52px 38px 20px 38px;
      margin: 0px 12px;
      background: linear-gradient(
        137deg,
        #edf2ff 0%,
        #fcfdff 52%,
        #f5f8ff 100%
      );
      border-radius: 8px 8px 8px 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .img {
        width: 210px;
        height: 210px;
        margin: auto;
      }
      .tip1 {
        height: 56px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 500;
        font-size: 24px;
        color: #000000;
        line-height: 32px;
      }
      .tip2 {
        height: 61px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        color: #536087;
        display: flex;
        align-items: flex-end;
      }
    }
    .box:hover {
      transition: all 1.2s ease-out;
      background: #4a8afc;
      box-shadow: 0px 8px 16px 1px rgba(46, 121, 255, 0.3);
      .tip1,
      .tip2 {
        color: #ffffff;
      }
    }
  }
}

.manage-box {
  height: 600px;
  width: 100%;
  position: relative;
  background-image: url("../assets/project/ct.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .title {
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: bold;
    font-size: 42px;
    color: #000000;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin: 100px auto 52px auto;
    height: fit-content;
  }
  .content {
    display: flex;
    margin: 0px auto;
    .box {
      transition: all 1.2s ease;
      width: 450px;
      height: 294px;
      background: linear-gradient(
        133deg,
        rgba(251, 252, 254, 0.94) 0%,
        #f6f9ff 100%
      );
      border-radius: 8px 8px 8px 8px;
      margin: 0px 12px;
      padding: 25px 24px 20px 40px;
      display: flex;
      flex-direction: column;
      .flex {
        display: flex;
      }
      .tips1 {
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: bold;
        font-size: 28px;
        color: #252d47;
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      .line {
        width: 55px;
        height: 3px;
        display: flex;
        margin-top: 42px;
        .line1 {
          width: 38px;
          height: 100%;
          background: #2e79ff;
        }
        .line2 {
          width: 18px;
          height: 3px;
          background: #ff892e;
        }
      }
      .img {
        width: 144px;
        height: 144px;
      }
      .tips2 {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        color: #536087;
        line-height: 32px;
      }
    }
    .box:hover {
      transition: all 1.2s ease-out;
      background: #4a8afc;
      box-shadow: 0px 8px 16px 1px rgba(46, 121, 255, 0.3);
      .tips1,
      .tips2 {
        color: #ffffff;
      }
      .line1 {
        background: #ffffff;
      }
    }
  }
}

.advantage-box {
  min-height: 600px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .title {
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: bold;
    font-size: 42px;
    color: #000000;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin: 91px auto 52px auto;
    height: fit-content;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .box {
      transition: all 1.2s ease;
      width: 688px;
      height: 220px;
      background: linear-gradient(
        270deg,
        rgba(230, 239, 255, 0.1) 0%,
        rgba(230, 239, 255, 0.6) 100%
      );
      border-radius: 8px 8px 8px 8px;
      margin-bottom: 24px;
      margin-left: 12px;
      margin-right: 12px;
      padding-left: 40px;

      .box-flex {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
      }
      .img {
        width: 116.58px;
        height: 118px;
        position: absolute;
        top: 25%;
      }
      .zsImg {
        width: 24px;
        height: 24px;
        background-image: url("../assets/project/zs.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      .text-left {
        display: flex;
        left: 44px;
        position: absolute;
        align-items: center;
      }
      .hoverTips {
        display: none;
      }
    }
    .tips {
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 600;
      font-size: 32px;
      color: #252d47;
      margin-left: 24px;
    }
    .box:hover {
      transition: all 1.2s ease-out;
      background: #4a8afc;
      box-shadow: 0px 8px 16px 1px rgba(46, 121, 255, 0.3);
      .box-flex {
        display: none;
      }
      .hoverTips {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        .hoverImg {
          width: 50px;
          height: 50px;
          margin-right: 30px;
        }
        .center-box {
          flex: 1;
          padding-right: 48px;
          .flex-direction {
            display: flex;
            flex-direction: column;
          }
          .tips {
            color: #ffffff;
            margin-left: 0px;
          }
          .tips2 {
            margin-top: 10px;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 400;
            font-size: 20px;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.client-box {
  min-height: 520px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .title {
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: bold;
    font-size: 42px;
    color: #000000;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin: 91px auto 52px auto;
    height: fit-content;
  }
  .content {
    display: grid;
    grid-template-columns: repeat(6, 180px);
    grid-column-gap: 64px;
    margin: 0px auto;
    .img {
      width: 180px;
      height: 84px;
    }
  }
}
.wh100 {
  width: 100%;
  height: 100%;
}
.ml24{
  margin-left: 24px;
  font-size: 20px;
  margin-top: 10px;
}
.manage-box2 {
    height: 746px;
    width: 100%;
    position: relative;
    background-image: url("../assets/project/rz_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .title {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      font-size: 42px;
      color: #000000;
      text-align: center;
      font-style: normal;
      text-transform: none;
      margin: 68px auto 44px auto;
      height: fit-content;
    }
    .mb20{
        margin-bottom: 20px !important;
    }
    .content {
      display: flex;
      margin: 0px auto;
      .box {
        transition: all 1.2s ease;
        width: 453px;
        height: 240px;
        background: linear-gradient(
          133deg,
          rgba(251, 252, 254, 0.94) 0%,
          #f6f9ff 100%
        );
        border-radius: 8px 8px 8px 8px;
        margin: 0px 12px;
        padding: 25px 24px 20px 40px;
        display: flex;
        flex-direction: column;
        .flex {
          display: flex;
        }
        .tips1 {
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: bold;
          font-size: 24px;
          color: #252d47;
          flex: 1;
          display: flex;
          flex-direction: column;
        }
        .line {
          width: 55px;
          height: 3px;
          display: flex;
          margin-top: 16px;
          .line2 {
            width: 20px;
            height: 4px;
            background: #FF892E;
          }
        }
        .tips2 {
            font-weight: 400;
            font-size: 16px;
            color: #778293;
            line-height: 28px;
            text-align: left;
        }
        .point-div{
          margin-top: 32px;
          line-height: 0;
        }
        .point-item{
          width: 50%;
          display: inline-block;
          font-size: 18px;
          line-height: 32px;
          div{
            display: inline;
          }
        }
        .o-dot{
          width: 6px;
          height: 6px;
          background: #FF892E;
          border-radius: 50%;
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
  }
  .rzzwxt{
        min-height: 600px;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        background: url(../assets/project/rz_bg2.png) no-repeat;
        background-size: 100% 100%;
        .title {
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: bold;
            font-size: 42px;
            color: #000000;
            text-align: center;
            font-style: normal;
            text-transform: none;
            margin: 68px auto 44px auto;
            height: fit-content;
        }
        .content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 86px;
            img{
                width: 1400px;
                height: 788px;
            } 
        }
  }
</style>
